
.container-contact .banner{
        background: #c5f0f9;
}

.contact-txt h1{
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
}

.contact-txt .form{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}

.contact-txt .form-control{
    width: 500px;
    border: none;
    background: #f5f5f5;
}

.contact-txt .addToCartBttn{
    background-color: lightcoral;
    border: none;
    min-width: 30px;
    padding: 10px 20px;
    border-radius: 3px;
    color: white;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
    background-color: lightcoral !important; 
}

.btn:hover {
    background-color: lightcoral !important; 
}

.formulario{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}

@media(max-width: 1100px){
    .img-form img{
        width: 400px;
    }
}

@media(max-width: 991px){
    .img-form img{
       display: none;;
    }
    .formulario {
         gap: 0; 
    }
}

@media(max-width: 530px){
    .contact-txt .form-control {
        width: 400px;
    }
}

@media(max-width: 405px){
    .contact-txt .form-control {
        width: 300px;
    }
}


