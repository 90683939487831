@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700;900&display=swap');
 
 *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.container{
    max-width: 1440px !important;
    width: 95%;
    margin: auto;
}

.containerMax{
    width: 100%;
}

.body{
    background: #F5F5F5;
    
}

